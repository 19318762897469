// Set up google analytics 4 (GA4)

import TagManager from 'react-gtm-module';
import dayjs from 'dayjs';

var dataLayer = dataLayer;

export function setupGTM(countryCode) {
  try {
    console.log('Initializing GTM...');

    if (typeof countryCode === 'string' && countryCode.length > 0) {
      const tags = {
        SE: 'GTM-TLBXZMD3',
        DK: 'GTM-PG2QM9QM',
        NO: 'GTM-N7KZ4X8T',
        FI: 'GTM-NVPDST9Z',
      };

      const gtmTagId = tags[countryCode];
      console.log('GTM Tag ID:', gtmTagId);

      if (gtmTagId) {
        // Ensure that GTM is not already initialized
        if (!window.dataLayer) {
          const tagManagerArgs = { gtmId: gtmTagId };
          TagManager.initialize(tagManagerArgs);
          console.log(`GTM initialized for ${countryCode}`);
        } else {
          console.log('GTM is already initialized');
        }
      } else {
        console.warn('No GTM Tag ID found for the provided country code:', countryCode);
      }
    } else {
      console.warn('Invalid country code:', countryCode);
    }
  } catch (error) {
    console.error('Failed to add GTM tag:', error);
  }
}

export function sendToDatalayer(data) {
  // Disable for production for now
  // if (process.env.NODE_ENV === 'production') {
  //   return;
  // }
  try {
    data.bookingflow_name = 'booking-2.0';
    // console.log('sendToDatalayer', JSON.stringify(data, undefined, 2));
    window.dataLayer = window.dataLayer || [];
    console.log('Window datalayer:', window?.dataLayer);
    // console.log('dataLayer', JSON.stringify(window.dataLayer, undefined, 2));
    window.dataLayer.push(data);
    console.log('GA4 data', data);
  } catch (error) {
    console.error('Failed to send to datalayer', error);
  }
}

export function ga4FormStarted(isLargeBooking, prefilled) {
  sendToDatalayer({
    event: isLargeBooking ? 'bookingrequest_flow_form_start' : 'booking_flow_form_start',
    prefilled: prefilled,
  });
}

export function ga4SendMessage() {
  sendToDatalayer({
    event: 'send_message',
  });
}

export function ga4FormSubmit(
  bookingId,
  countryCode,
  venueId,
  seatings,
  kids,
  date,
  hour,
  minute,
  isApp,
) {
  sendToDatalayer({
    event: 'booking_flow_form_submit',
    'booking id': bookingId,
    market: countryCode,
    restaurant: venueId,
    'amount of total guests': seatings,
    'amount of child guest': kids,
    'seating date': dayjs(date).format('YYYYMMDD'),
    'seating time': `${hour.length < 2 ? '0' + hour.toString() : hour}:${minute.toString() == '0' ? '00' : minute}`,
    'booking date': dayjs().format('YYYYMMDD'),
    'app booking': isApp,
    // login_status: ,
    // membership_status: ,
    // real_lifetime_value: ,
    // pebv: ,
  });
}

export function ga4RequestFormSubmit(
  bookingId,
  countryCode,
  venueId,
  seatings,
  kids,
  date,
  hour,
  minute,
  isApp,
) {
  sendToDatalayer({
    event: 'bookingrequest_flow_form_submit',
    'booking id': bookingId,
    market: countryCode,
    restaurant: venueId,
    'amount of total guests': seatings,
    'amount of child guest': kids,
    'seating date': dayjs(date).format('YYYYMMDD'),
    'seating time': `${hour.length < 2 ? '0' + hour.toString() : hour}:${minute.toString() == '0' ? '00' : minute}`,
    'booking date': dayjs().format('YYYYMMDD'),
    'app booking': isApp,
    // login_status: ,
    // membership_status: ,
    // real_lifetime_value: ,
    // pebv: ,
  });
}

export function ga4SelectedDate(year, month, day) {
  sendToDatalayer({
    event: 'booking_flow_selected_seating_date',
    'seating date': dayjs(year + '-' + month + '-' + day).format('YYYYMMDD'),
    'booking date': dayjs().format('YYYYMMDD'),
  });
}

export function ga4SelectedGuests(seatings, kids) {
  sendToDatalayer({
    event: 'booking_flow_selected_guests',
    'amount of total guest': seatings,
    'amount of child guest': kids,
  });
}

export function ga4SelectedTime(hour, minute, requestedTime, suggestedTime, suggestedTimeShown) {
  console.log('Req time', requestedTime);
  sendToDatalayer({
    event: 'booking_flow_selected_seating_time',
    'seating time': `${hour.length < 2 ? '0' + hour.toString() : hour}:${minute.toString() == '0' ? '00' : minute}`,
    'requested seating time': requestedTime,
    'suggested seating time': suggestedTime,
    'shown suggested seating time': suggestedTimeShown,
  });
}

export function ga4CancelReservation(bookingId) {
  sendToDatalayer({
    event: 'booking_cancellation',
    bookingId,
  });
}

export function ga4BookingFlowStarted(countryCode, venueId, isApp) {
  sendToDatalayer({
    event: 'booking_flow_started',
    market: countryCode,
    restaurant: venueId,
    'app booking': isApp,
    // login_status: '0701234566',
  });
}

export function ga4TimeConflict() {
  sendToDatalayer({
    event: 'booking_time_conflict',
  });
}
